/* Hero Block overrides */
.hero-block {
  margin-bottom: 1.5em;
}
.hero-block-text h2 {
  font-size: 4em;
  border-radius: 5%;
  text-shadow: -3px -3px 10px #252426, 3px -3px 10px #252426, -3px 3px 10px #252426, 3px 3px 10px #252426;
}
/* Make tabs block horizontally scrollable on mobile */
/* reference-tabs is a custom class set via block styling */
.reference-tabs .tabs-block .tabs .ui.menu.center {
  overflow-y: hidden;
  overflow-x: visible;
  justify-content: left !important;
}
/* Min width is the size of the actual block in deployment */
@media only screen and (min-width: 1700px) {
  .reference-tabs .tabs-block .tabs .ui.menu.center {
    overflow-y: initial;
    overflow-x: initial;
    justify-content: center !important;
  }
}
