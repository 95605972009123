.navigation.navigation-dropdownmenu {
  position: relative;
}
.navigation.navigation-dropdownmenu .pointing.secondary.open {
  position: fixed;
  background-color: white;
  z-index: 3;
  top: 0;
  right: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.hamburger.is-active .hamburger-box {
  position: fixed !important;
}
.ui.pointing.secondary.stackable.open.menu span {
  font-size: 1.4em;
}
.mobileNavWrapper {
  display: flex;
  flex-direction: column;
}
.dropdown-menu-wrapper.open {
  border-top: none !important;
}
.dropdown-menu-wrapper {
  display: none;
}
.dropdown-menu-inner .ui.segment {
  border: none;
  box-shadow: unset;
}
.dropdown-menu-inner .ui.segment .three.wide.column {
  width: -moz-fit-content !important;
  width: fit-content !important;
  padding: 11px;
}
.dropdown-menu-inner .ui.segment {
  padding-right: 0;
  padding-left: 0;
}
.dropdown-menu-inner .ui.segment a {
  color: #9E2A2B;
  width: -moz-fit-content !important;
  width: fit-content !important;
}
.dropdown-menu-inner .ui.segment .ui.container.grid {
  flex-direction: column;
  margin-right: 0 !important;
  margin-left: 0 !important;
}
.dropdown-menu-inner .ui.segment .ui.container.grid div {
  display: flex;
  justify-content: center;
}
.dropdown-menu-wrapper.open {
  z-index: 2;
  display: block;
  border-top: 1px solid grey;
}
.dropdown-menu-wrapper > .ui.segment {
  position: relative;
  width: 100%;
  border-top: 0;
  border-right: 0;
  border-left: 0;
}
body.has-toolbar.has-sidebar .dropdown-menu-wrapper {
  left: 80px;
  width: calc(100% - 375px - 80px);
}
body.has-toolbar-collapsed.has-sidebar .dropdown-menu-wrapper {
  left: 20px;
  width: calc(100% - 375px - 20px);
}
body.has-toolbar-collapsed.has-sidebar-collapsed .dropdown-menu-wrapper {
  left: 20px;
  width: calc(100% - 20px - 20px);
}
body.has-toolbar.has-sidebar-collapsed .dropdown-menu-wrapper {
  left: 80px;
  width: calc(100% - 20px - 80px);
}
body.has-toolbar-collapsed:not(.has-sidebar):not(.has-sidebar-collapsed) .dropdown-menu-wrapper {
  left: 20px;
  width: calc(100% - 20px);
}
.dropdownmenu-close-button-wrapper {
  text-align: right;
}
.ui.basic.button.dropdown-close-button {
  color: inherit !important;
  font-size: 1.5rem;
}
@media (max-width: 766px) {
  .ui.basic.button.dropdown-close-button {
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: -1px !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    white-space: nowrap !important;
    border: 0 !important;
  }
  .dropdown-menu-wrapper {
    position: static !important;
  }
  .dropdown-menu-wrapper .ui.grid .column.column {
    width: 100% !important;
  }
}
.dropdown-menu-wrapper .dropdownmenu-footer {
  padding: 1em;
  text-align: right;
}
@media (min-width: 767px) {
  .dropdown-menu-wrapper .dropdownmenu-footer {
    margin-top: 1em;
  }
}
.ui.menu > .ui.button.item.dropdownmenu-item.active.open,
.ui.menu > .ui.button.item.dropdownmenu-item.active.open:hover {
  border-color: grey;
}
.ui.menu > .ui.button.item.dropdownmenu-item i.dropdown.icon {
  margin-left: 0.5rem;
}
@media only screen and (min-width: 1070px) {
  .dropdownmenu-item {
    position: relative;
  }
  .dropdown-menu-wrapper {
    position: absolute !important;
    z-index: 1;
    top: 50px;
    display: none;
    overflow: hidden;
    width: 100%;
    border-top: none !important;
  }
  .dropdown-menu-inner .ui.segment {
    border: 1px solid #fff;
    border-radius: 10px;
    padding: 1.5em 2em;
  }
  .dropdown-menu-wrapper:before {
    position: relative;
    left: 10%;
    margin-left: -20px;
    top: -9px;
    width: 0;
    height: 0;
    content: '';
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #fff;
  }
  .dropdown-menu-inner .ui.segment .ui.container.grid {
    flex-direction: column;
    width: -moz-fit-content !important;
    width: fit-content !important;
  }
  .dropdown-menu-inner .ui.segment .ui.container.grid div,
  .dropdown-menu-inner .ui.segment {
    width: -moz-fit-content !important;
    width: fit-content !important;
  }
  .dropdown-menu-inner .ui.segment .ui.container.grid div a {
    color: #fff;
    font-size: 1.1em;
  }
  body.has-toolbar:not(.has-sidebar):not(.has-sidebar-collapsed) .dropdown-menu-wrapper {
    width: calc(100% - 80px);
  }
}
@media only screen and (min-width: 1200px) {
  .dropdown-menu-inner .ui.segment .ui.container.grid,
  body:not(.has-toolbar):not(.has-sidebar):not(.has-toolbar-collapsed):not(.has-sidebar-collapsed) .dropdown-menu-inner .ui.container {
    flex-direction: column;
    width: -moz-fit-content !important;
    width: fit-content !important;
  }
}
